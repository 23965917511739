<template>
  <div class="suspend-btn">
    <div class="hover-group">
      <div class="group-btn">
        <v-row class="pa-0" @click.stop="currentHover = 'phone'" @mouseenter="currentHover = 'phone'" @mouseleave="currentHover = ''">
          <v-img width="50" height="50" contain src="../../public/img/icons/menu/phone.png"></v-img>
        </v-row>
        <div class="d-flex justify-center">
          <div style="width: 30%"><v-divider></v-divider></div>
        </div>
        <v-row class="pa-0" @click.stop="currentHover = 'tryout'">
          <v-img width="50" height="50" contain src="../../public/img/icons/menu/tryout.png"></v-img>
        </v-row>
        <div class="d-flex justify-center">
          <div style="width: 30%"><v-divider></v-divider></div>
        </div>
        <v-row class="pa-0" @click.stop="currentHover = 'follow'" @mouseenter="currentHover = 'follow'" @mouseleave="currentHover = ''">
          <v-img width="50" height="50" contain src="../../public/img/icons/menu/follow.png"></v-img>
        </v-row>
      </div>
      <div class="hover-content" @click.stop>
        <transition name="fade" mode="out-in">
          <div class="content-phone"
               @mouseenter="currentHover = 'phone'"
               @mouseleave="currentHover = ''"
               v-show="currentHover === 'phone'">
            <v-card color="transparent" flat>
              <v-row class="pa-0">
                <div class="d-inline width-40">
                  <v-avatar size="40" rounded-2>
                    <v-img contain src="../../public/img/icons/menu/cooperation.png"></v-img>
                  </v-avatar>
                </div>
                <div class="d-inline ml-3 mt-n3">
                  <v-card color="transparent" flat>
                    <v-card-title class="pa-0 font-size-20 mt-2">
                      商户合作
                    </v-card-title>
                    <v-card-text class="pa-0 mt-3 font-size-16" style="line-height: 1.4">business@ophyer.com</v-card-text>
                  </v-card>
                </div>
              </v-row>
              <v-row class="pa-0 mt-8">
                <div class="d-inline width-40">
                  <v-avatar size="40" rounded-2>
                    <v-img width="40" height="40" contain src="../../public/img/icons/menu/consult.png"></v-img>
                  </v-avatar>
                </div>
                <div class="d-inline ml-3 mt-n3">
                  <v-card color="transparent" flat>
                    <v-card-title class="pa-0 font-size-20 mt-2">
                     产品咨询/加盟
                    </v-card-title>
                    <v-card-text class="pa-0 mt-3 font-size-16" style="line-height: 1.4">4006189949</v-card-text>
                  </v-card>
                </div>
              </v-row>
            </v-card>
          </div>
        </transition>
        <transition name="fade" mode="out-in">
          <div class="content-tryout" v-show="currentHover === 'tryout'">
            <v-card color="transparent" flat>
              <v-card-title class="justify-end pb-0 close-btn" @click.stop="currentHover = '';">x</v-card-title>
              <v-card-title class="justify-center font-size-28 pa-0">完善信息，专属顾问马上联系您</v-card-title>
              <v-card-text class="text-align-center font-size-18 pa-0 mt-5">4006189949</v-card-text>
              <v-form class="pa-0 mt-12">
                <v-container class="pa-0">
                  <v-row class="pa-0">
                    <v-col cols="12" class="pa-0 d-flex justify-center">
                      <div>
                        <div class="font-size-18 font-weight-bold">
                          姓名
                          <span class="ml-2" style="color: red;vertical-align: sub;">*</span>
                        </div>
                        <input placeholder="请输入您的姓名" v-model="form.name"/>
                      </div>
                    </v-col>
                    <v-col cols="12" class="pa-0 d-flex justify-center mt-5">
                      <div>
                        <div class="font-size-18 font-weight-bold">
                          电话
                          <span class="ml-2" style="color: red;vertical-align: sub;">*</span>
                        </div>
                        <input placeholder="请输入您的电话" v-model="form.phone"/>
                      </div>
                    </v-col>
                    <v-col cols="12" class="pa-0 d-flex justify-center mt-5">
                      <div>
                        <div class="font-size-18 font-weight-bold">
                          公司名称
                          <span class="ml-2" style="color: red;vertical-align: sub;">*</span>
                        </div>
                        <input placeholder="请输入您的公司名称" v-model="form.conpanyName"/>
                      </div>
                    </v-col>
                    <v-col cols="12" class="pa-0 d-flex justify-center mt-5">
                      <div>
                        <div class="font-size-18 font-weight-bold">地区</div>
                        <input placeholder="请输入您所在的地区" v-model="form.address"/>
                      </div>
                    </v-col>
                    <v-col cols="12" class="pa-0 d-flex justify-center mt-9">
                      <div class="tryout-btn" @click.stop="handleSaveTryout">提交</div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card>
          </div>
        </transition>
        <transition name="fade" mode="out-in">
          <div class="content-follow"
               @mouseenter="currentHover = 'follow'"
               @mouseleave="currentHover = ''"
               v-show="currentHover === 'follow'">
            <v-card color="transparent" flat>
              <v-row class="pa-0 justify-center">
                <v-img contain src="../../public/img/icons/menu/qrCode.png" width="180" height="180"></v-img>
                <v-card-title class="font-size-16 pa-0">关注飞天云动公众号</v-card-title>
              </v-row>
            </v-card>
          </div>
        </transition>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <div class="hover-btn"
           v-show="officialWebsite.showReturnTip"
           @click.stop="$vuetify.goTo(0, {})">
        <v-img src="../../public/img/icons/menu/returnTop.png"></v-img>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "CustomizeSuspendBtn",
  data: () => ({
    currentHover: "",
    form: {
      name: "",
      phone: "",
      conpanyName: "",
      address: ""
    },
  }),
  computed: {
    ...mapState(["officialWebsite"]),
  },
  methods: {
    handleSaveTryout: function (){
      if(!this.form.name || !this.form.phone || !this.form.conpanyName){
        this.$lavMessage.warning("请填写必填项！");
        return;
      }
      this.$lavMessage.success("提交成功！");
      this.currentHover = "";
    },
  }
}
</script>
<style lang="scss" scoped>
.suspend-btn{
  position: fixed;
  bottom: 20%;
  right: 20px;
  z-index:1001;
  ::v-deep .v-btn__content{
    white-space: initial;
    display: unset;
    flex: unset;
  }
  .btn-info{
    border-radius: 5px;
  }
  .hover-group{
    position: relative;
    .group-btn {
      width: 52px;
      height: 170px;
      padding-top: 10px;
      border-radius: 25px;
      box-shadow: 0px 0px 10px 2px rgb(0 114 255 / 10%);
      background-color: #FFFFFF;
      cursor: pointer;
    }
    .hover-content{
      position: absolute;
      right: 72px;
      bottom: 0;
      .content-phone{
        width: 280px;
        height:232px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FFFFFF;
        border-radius: 10px;
        box-shadow: 0px 0px 10px 2px rgb(0 114 255 / 10%);
      }
      .content-follow{
        width: 200px;
        height: 220px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FFFFFF;
        border-radius: 10px;
        padding-bottom: 10px;
        box-shadow: 0px 0px 10px 2px rgb(0 114 255 / 10%);
      }
    }
    .content-tryout{
      width: 600px;
      height: 670px;
      display: flex;
      justify-content: center;
      border-radius: 10px;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      background-color: #FFFFFF;
      box-shadow: 0px 0px 15px 5px rgb(0 114 255 / 10%);
      input {
        width: 360px;
        height: 50px;
        border: 1px solid rgba(127, 127, 127, 0.5);
        border-radius: 8px;
        margin-top: 12px;
        padding: 16px 14px;
      }
      input:focus {//获取焦点
        outline: unset;//边框不用border，用outline
        background: rgba(3, 16, 28, 0);//背景色
      }
      .tryout-btn {
        width: 360px;
        height: 60px;
        background: #2D87FF;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
        letter-spacing:8px;
        cursor: pointer;
      }
      .close-btn{
        color: rgba(0, 0, 0, 0.6) !important;
        cursor: pointer;
      }
    }
  }
  .hover-btn{
    width: 50px;
    height: 50px;
    border-radius: 25px;
    position: fixed;
    bottom: calc(20% - 70px);
    right: 20px;
    z-index:5;
    box-shadow: 0px 0px 10px 2px rgb(0 114 255 / 10%);
    cursor: pointer;
  }
}
</style>