<template>
  <v-footer
      color="#272b2e"
      height="300"
      width="100%"
      class="justify-center align-start"
  >
    <v-container class="pa-0 footer-container">

      <v-row class="mt-9" :class="isIE == true ? 'isIE' : ''">
        <v-col cols="8" class="d-flex justify-start pa-0">
          <v-card color="transparent" flat class="pt-2">
            <div class="d-inline-flex ml-n1">
              <v-img
                  alt="logo"
                  contain
                  src="../assets/login/logo_bai.png"
                  width="130"
              />
              <v-divider vertical dark class="mx-5"></v-divider>
              <v-img
                  alt="logo"
                  contain
                  src="../assets/login/wanmuyun-logo-bai.png"
                  width="100"
              />
            </div>
            <v-card-title class="pl-0 pb-6 pt-8 content-info">
                <!--<span class="content-info" style="line-height: 1.2;width:350px;">-->
                <!--  飞天云动专注智慧运营技术，利用AR/VR引擎、 AI行为算法、云计算等技术能力，为电商、教育、文旅等各垂直行业、企业业务发展赋能-->
                <!--</span>-->
              <span class="content-info" style="line-height: 1.2;width:100%;">
                  <!-- 飞天云动专注智慧运营技术，利用AR/VR引擎、 AI行为算法、云计算等技术能力，为电商、教育、文旅等各垂直行业、企业业务发展赋能 -->
                  飞天云动专注智慧数字化技术，利用AR/VR引擎、 AI行为算法、云计算等技术能力，为电商、教育、文旅等各垂直行业、企业业务发展赋能
              </span>
            </v-card-title>
            <v-card-title class="pa-0 content-info">
              <span class="content-info">方案咨询：4006189949</span>
              <span class="ml-6 content-info">周一到周日 9：00-18：00</span>
            </v-card-title>
            <v-card-title class="pa-0 mt-1">
              <span class="content-info">北京朝阳区广渠路3号竞园艺术中心商8</span>
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="1" class="d-flex justify-center pa-0" style="height: 180px;">
          <v-divider vertical dark></v-divider>
        </v-col>
        <!--<v-col cols="4" class="pa-0">-->
        <!--  <v-card color="transparent" flat class="pt-2">-->
        <!--    <v-card-text class="pa-0 font-size-16 font-weight-bold color-FFF">产品与服务</v-card-text>-->
        <!--    <div class="footer-divider"></div>-->
        <!--    <div class="d-flex mt-5">-->
        <!--      <v-card color="transparent" flat-->
        <!--              :class="index > 0 ? 'ml-12':''"-->
        <!--              v-for="(footerLink, index) in serviceProducts" :key="index">-->
        <!--        <v-hover v-slot="{ hover }" v-for="(linkInfo, linkIndex) in footerLink" :key="linkIndex">-->
        <!--          <v-card-text class="pa-0 content-info cursor-pointer font-size-12"-->
        <!--                       :class="[linkIndex > 0 ? 'py-1':'pb-1',hover ? 'bg-hover':'']"-->
        <!--                       @click.stop="handleFooterClick(linkInfo)">{{ linkInfo.title }}</v-card-text>-->
        <!--        </v-hover>-->
        <!--      </v-card>-->
        <!--    </div>-->
        <!--  </v-card>-->
        <!--</v-col>-->
        <v-col cols="2" class="pa-0">
          <v-card color="transparent" flat class="pt-2">
            <!--<v-card-text class="pa-0 font-size-16 font-weight-bold color-FFF">解决方案</v-card-text>-->
            <v-card-text class="pa-0 font-size-16 font-weight-bold color-FFF">核心产品</v-card-text>
            <div class="footer-divider" style="width: 57px"></div>
            <div class="mt-5">
              <v-card color="transparent" flat
                      :class="index > 0 ? 'ml-12':''"
                      v-for="(footerLink, index) in solutions" :key="index">
                <v-hover v-slot="{ hover }" v-for="(linkInfo, linkIndex) in footerLink" :key="linkIndex">
                  <v-card-text class="pa-0 content-info cursor-pointer font-size-12"
                               :class="[linkIndex > 0 ? 'py-2':'pb-1',hover ? 'bg-hover':'']"
                               @click.stop="handleFooterClick(linkInfo)">{{ linkInfo.title }}</v-card-text>
                </v-hover>
              </v-card>
            </div>
          </v-card>
        </v-col>
        <v-col cols="1" class="pa-0">
          <v-card color="transparent" flat class="mt-5">
            <v-card-text class="d-flex justify-center pa-0 qr-code">
              <v-img
                  alt="二维码"
                  contain
                  src="../../public/img/icons/menu/qrCode.png"
                  width="108"
                  max-width="108"
                  height="108"
                  max-height="108"
              />
            </v-card-text>
            <v-card-text class="content-info d-flex justify-center pa-0 mt-3 font-size-12">官方公众号</v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <div class="width-per-100 d-flex justify-center align-center" style="position: absolute;bottom: 0;left: 0;height: 60px;background-color: #090E19">
          <div class="max-width-1200" style="color: #969799;font-size: 12px;">
            <!--© COPYRIGHT 2021 . ALL RIGHTS RESERVED. 北京飞天云动科技股份有限公司版权所有-->
            © COPYRIGHT 2022 . ALL RIGHTS RESERVED.
            <!-- <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502037637" target="_blank">京公网安备11010502037637号</a> -->
            <a href="https://beian.miit.gov.cn/" class="color" target="_blank" style="margin-left:5px">京ICP备2022001220号-3</a>
            <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502053993" class="color" style="margin-left:5px" target="_blank">京公网安备11010502053993号</a>
          </div>
        </div>
    </v-container>
  </v-footer>
</template>

<script>
import { browserVersion } from "@/utils/utils"

export default {
  name: "CustomizeFooter",
  data: () => ({
    serviceProducts: [
      [
        {
          title: "AR创意运营",
          router: "/ARcreativeMarketing/home",
          isNewWindow: true
        },
        {
          title: "VR全景网站",
          router: "/vrPanorama/home",
          isNewWindow: true
        },
        {
          title: "运营导流",
          router: "/marketingDiversion/home",
          isNewWindow: true
        },
        // {
        //   title: "IP授权服务",
        //   router: "/IP/Home",
        //   isNewWindow: true
        // },
        {
          title: "电商商城",
          router: "/OnlineRetailersStore/Home",
          isNewWindow: true
        }
      ],
      [
        {
          title: "3D场景编辑器",
          router: "/SceneEditor3D/Home",
          isNewWindow: true
        },
        {
          title: "虚拟直播",
          router: "",
          outerChain: "http://iwmy.ophyer.com/virtualPlay",
          isNewWindow: true
        },
        {
          title: "微信公众号助手",
          router: "/assistantAccount/Home",
          isNewWindow: true
        },
        {
          title: "AR打卡",
          router: "/arPunch/Home",
          isNewWindow: true
        },
        {
          title: "短信服务",
          router: "/shortMessage/Home",
          isNewWindow: true
        }
      ],
      [
        {
          title: "大数据分析",
          router: "/bigDataAnalysis/home",
          isNewWindow: true
        },
        {
          title: "大客户定制运营",
          router: "/customers/home",
          isNewWindow: true
        },
        {
          title: "AR/VR在线课程",
          router: "",
          outerChain: "http://edu.ophyer.com/inxedu/front/showcoulist/1",
          isNewWindow: true
        },
        {
          title: "AI智能",
          router: "/AI/AIHome",
          isNewWindow: true
        },
        {
          title: "视听云",
          router: "/audiovisualCloud/home",
          isNewWindow: true
        }
      ]
    ],
    solutions: [
      [
        {
          // title: "AR互动运营",
          // title: "AR创意运营",
          title: "AR创意互动",
          router: "/solutions/solutionAR"
        },
        {
          // title: "VR全景运营",
          title: "VR全景网站",
          router: "/solutions/solutionVR"
        }
      ]
    ],
    isIE: browserVersion()
  }),
  mounted() {
  },
  methods: {
    handleFooterClick: function (content){
      if(content){
        if(content.outerChain){
          window.open(content.outerChain);
        }else if(content.router){
          if(content.isNewWindow){
            window.open(window.location.protocol + "//" + window.location.host + content.router)
          }else {
            this.$router.push(content.router)
          }
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
footer {
  position: relative;
  // z-index: 5;
  .footer-container {
    width: 100%;
    max-width: 1200px;
    .isIE {
      width: 1200px;
      margin: 0 auto;
      margin-left: -300px !important;
    }
    .content-info {
      color: #AEB8C8;
      font-size: 14px;
    }
    .footer-divider {
      width: 70px;
      height: 1px;
      background: #AEB8C8;
      margin-top: 5px;
    }
    ::v-deep .qr-code{
      .v-image__image--contain{
        width: 108px;
      }
    }
    .color{
      color: rgb(150, 151, 153);
      text-decoration-line: none;
    }
  }
}
</style>