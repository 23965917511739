<template>
  <v-app-bar
      app
      color="white"
      height="60"
      dense
      class="app-bar"
  >
    <v-row class="width-per-100 height-per-100 ma-0 justify-center" >
      <div class="width-per-100 height-per-100 d-flex justify-space-between menu">
        <div style="width: 260px;">
          <v-btn
              plain elevation="0"
              height="100%"
              min-width="100"
              :to="homeRouter" 
              class="pl-0 logo-btn"
              :ripple="false"
          >
            <v-img
                alt="logo"
                contain
                position="left center"
                src="../assets/login/wanmuyun-logo.png"
                height="30"
                width="110"
            />
            <div style="height: 20px;" v-if="homeTitle"><v-divider vertical class="ml-3" style="border-color: #000000;opacity: 0.1"></v-divider></div>
            <div class="ml-3 font-size-20 customize-sub-station">{{ homeTitle }}</div>
          </v-btn>
        </div>
        <div class="d-flex justify-center width-per-100">
          <v-hover
              v-slot="{ hover }"
              v-for="(link,index) in links"
              :key="index">
            <div class="height-per-100 d-inline mx-8">
              <v-btn
                  height="100%"
                  light depressed plain
                  :color="currentMenu === link.router ? '#0568FD':''"
                  :to="link.children.length > 0 ? '':link.router"
                  :ripple="false"
                  class="px-0"
                  @click.stop="handleMenuClick(link)"
              >
                <div class="menu-title">{{ link.title }}
                  <div :class="currentMenu === link.router ? 'menu-btn-active':''"></div>
                </div>
              </v-btn>
            </div>
          </v-hover>
        </div>
        <div class="d-inline-flex justify-center align-center" style="min-width: 150px;">
          <v-img
              alt="logo"
              contain
              src="../../public/img/icons/wmyHome/icon-phone.png"
              height="30"
              width="30"
          />
          <div class="font-size-16">4006189949</div>
        </div>
      </div>
    </v-row>
  </v-app-bar>
</template>

<script>
import { browserVersion } from "@/utils/utils"

import { mapState } from "vuex";
export default {
  name: "CustomizeHeader",
  props: {
    homeRouter: {
      type: String,
      default() {
        return "/";
      }
    },
    homeTitle: {
      type: String,
      default() {
        return "";
      }
    },
    links: {
      type: Array,
      default() {
        return [];
      }
    },
    isIE: browserVersion()
  },
  data: () => ({
    currentMenu: "/",
  }),
  watch: {
    $route(to) {
      this.currentMenu = to.path;
    }
  },
  computed: {
    ...mapState(["officialWebsite"]),
  },
  mounted() {
    console.log(22232323)
    this.currentMenu = this.$route.path;
    console.log(this.isIE,'isIE isIE isIE ')
  },
  methods: {
    handleMenuClick: function (menu, clickChildren, content){
      if(menu.children.length !== 0 && !clickChildren) return;
      if(content && content.router){
        if(content.isNewWindow){
          window.open(window.location.protocol + "//" + window.location.host + content.router)
        }else {
          this.$router.push(content.router)
        }
      }
      this.showMenuChildren = false;
    }
  }
}
</script>
<style lang="scss" scoped>

.app-bar{
  line-height: 1;
  box-shadow: 0px 10px 20px 0px rgba(0, 114, 255, 0.1) !important;
  z-index: 1000 !important;
  hr {
    border-color: #333333;
    opacity: 0.2;
  }
  .v-toolbar__content {
    padding: 0;
    .logo-btn{
      margin-left: -32px;
      .v-btn__content{
        opacity: 1 !important;
        height: 100%;
      }
    }
    .menu{
      width: 1200px !important;
      margin: 0 !important;
      .v-btn:not(.v-btn--round).v-size--default{
        min-width: unset;
      }
      .v-btn__content {
        opacity: unset !important;
      }
    }
    .menu-hover {
      border-bottom: 2px solid #333333;
    }
    .menu-title{
      margin-top: 10px;
      font-size: 16px;
      height: 30px;
      position: relative;
      .menu-btn-active{
        position: absolute;
        bottom: 3px;
        left: 50%;
        transform: translate(-50%);
        height: 3px;
        width: 30px;
        background-color: #0568FD;
      }
    }
  }
}
</style>