import Vue from 'vue';
import Vuex from 'vuex';
import officialWebsite from "./officialWebsite";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    officialWebsite
  }
})
